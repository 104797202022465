/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */

const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://pwa-oms-sinarmas.testingnow.me',
    stage: 'https://omssinarmas-pwa.huawei-staging.testingnow.me',
    prod: 'https://vendor.bixbox.co.id',
};
// const HOST = {
//     local: 'http://localhost:3000',
//     dev: 'http://localhost:3000',
//     stage: 'http://localhost:3000',
//     prod: 'http://localhost:3000',
// };

const channelHost = {
    local: 'http://localhost:3000',
    dev: 'https://pwa-sinarmasapp.testingnow.me',
    stage: 'https://sinarmasapp-pwa.huawei-staging.testingnow.me',
    prod: 'https://bixbox.co.id',
};

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://oms-sinarmas.testingnow.me/graphql',
    dev: 'https://oms-sinarmas.testingnow.me/graphql',
    stage: 'https://oms-sinarmas.huawei-staging.testingnow.me/graphql',
    prod: 'https://oms.bixbox.co.id/graphql',
};

/* Multiple GraphQL Endpoint */
const graphqlEndpointMultiple = {
    name: 'sinarmasApp',
    token_local: '281d41ly2rrc2dzkqirh9g2z4ynhlqm2',
    token_dev: '281d41ly2rrc2dzkqirh9g2z4ynhlqm2',
    token_stage: '281d41ly2rrc2dzkqirh9g2z4ynhlqm2',
    token_prod: 'haee0jceyaiaqwancvnfmqziwysqtnj5',
    local: 'https://sinarmasapp.testingnow.me/graphql',
    dev: 'https://sinarmasapp.testingnow.me/graphql',
    stage: 'https://sinarmasapp.huawei-staging.testingnow.me/graphql',
    prod: 'https://web.bixbox.co.id/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Swift APP';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'en', // just change to your default language
    languages: ['en', 'id'], // array code language what you want
    // language label code
    languagesLabel: {
        en: 'English',
        id: 'Indonesia',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: false,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: '', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: false,
    siteKey: {
        local: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        dev: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        stage: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        prod: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
    },
};

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = new Date(Date.now() + 1000 * 60 * 60);
const expiredDefault = 365;
const localResolverKey = 'resolver';

function firebaseConfig() {
    let env = 'local';
    if (typeof window !== 'undefined') {
        env = window.APP_ENV;
    } else {
        env = process.env.APP_ENV;
    }
    // eslint-disable-next-line no-unused-vars
    const config = {
        local: {
            apiKey: 'AIzaSyDylsxtLYQkl-chxhISGxwIxAUqEuLFP_g',
            authDomain: 'sinarmasapp-dev.firebaseapp.com',
            projectId: 'sinarmasapp-dev',
            storageBucket: 'sinarmasapp-dev.appspot.com',
            messagingSenderId: '137259392856',
            appId: '1:137259392856:web:cb45270bf303057fbd0f2d',
            measurementId: 'G-Q5XP0V4HB3',
        },
        dev: {
            apiKey: 'AIzaSyDylsxtLYQkl-chxhISGxwIxAUqEuLFP_g',
            authDomain: 'sinarmasapp-dev.firebaseapp.com',
            projectId: 'sinarmasapp-dev',
            storageBucket: 'sinarmasapp-dev.appspot.com',
            messagingSenderId: '137259392856',
            appId: '1:137259392856:web:cb45270bf303057fbd0f2d',
            measurementId: 'G-Q5XP0V4HB3',
        },
        stage: {
            apiKey: 'AIzaSyDylsxtLYQkl-chxhISGxwIxAUqEuLFP_g',
            authDomain: 'sinarmasapp-dev.firebaseapp.com',
            projectId: 'sinarmasapp-dev',
            storageBucket: 'sinarmasapp-dev.appspot.com',
            messagingSenderId: '137259392856',
            appId: '1:137259392856:web:cb45270bf303057fbd0f2d',
            measurementId: 'G-Q5XP0V4HB3',
        },
        prod: {
            apiKey: 'AIzaSyDylsxtLYQkl-chxhISGxwIxAUqEuLFP_g',
            authDomain: 'sinarmasapp-bixbox.firebaseapp.com',
            databaseURL: 'https://sinarmasapp-bixbox.firebaseio.com',
            projectId: 'sinarmasapp-bixbox',
            storageBucket: 'sinarmasapp-bixbox.appspot.com',
            messagingSenderId: '120348119862',
            appId: '1:120348119862:web:2e29b9317fb757f95d1997',
            measurementId: 'G-4MXWXS1EM5',
        },
    };
    return config[env];
}

const features = {
    ssrCache: false,
    facebookMetaId: {
        enabled: false,
        app_id: '', // if enabled add fb app id here. e.g. 3080154482073095
    },
    vesMenu: {
        enabled: true,
    },
    customInstallApp: {
        enabled: true,
    },
    chatSystem: {
        enabled: true,
        graphqlEndpoint: {
            local: 'https://chat-sinarmasapp.testingnow.me/graphql',
            dev: 'https://chat-sinarmasapp.testingnow.me/graphql',
            stage: 'https://sinarmasapp-chat.huawei-staging.testingnow.me/graphql',
            prod: 'https://chat.bixbox.co.id/graphql',
        },
        graphqlContext: 'chatSystem',
        config: firebaseConfig(),
    },
    // pushNotification: {
    //     enabled: false,
    //     config: {
    //         apiKey: 'AIzaSyBwAPEXdjKf84q-T7tUxVJBcOJJ8hzrXTI',
    //         authDomain: 'swift-pwa.firebaseapp.com',
    //         databaseURL: 'https://swift-pwa.firebaseio.com',
    //         projectId: 'swift-pwa',
    //         storageBucket: 'swift-pwa.appspot.com',
    //         messagingSenderId: '1029426161575',
    //         appId: '1:1029426161575:web:2c57e3f74cb00e0132f882',
    //         measurementId: 'G-VSRV1DJVSQ',
    //     },
    // },
};

const nossrCache = [
    '/graphql',
];

const debuging = {
    originalError: false,
};

/* Translation CSV Dir */
const translationCSVDir = 'public/static/locales_csv/';
const translationJSONDir = 'public/static/locales/';

module.exports = {
    sentry,
    debuging,
    GTM,
    HOST,
    channelHost,
    graphqlEndpoint,
    graphqlEndpointMultiple,
    shareIcon,
    passwordStrength,
    translation,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    features,
    nossrCache,
    recaptcha,
    installMessage,
    appName,
    localResolverKey,
    translationCSVDir,
    translationJSONDir,
};
